import React from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

import LayoutInnerPage from '@src/layouts/InnerPage'
import Seo from '@components/seo/Index'
import RealEstateBanner from '@components/global/banner/RealEstateBanner'
import Best10RealEstate from '@components/global/gallery/Best10RealEstate'
import Best10Features from '@components/global/product/Best10Features'
import ImagineStudioReviews from '@components/pages/real-estate-websites/imaginestudio/Reviews'
import LeadContactForm from '@components/global/forms/LeadContactForm/Index'

import {
  pageData as page,
  bannerData,
  listData,
  featuresData,
} from '@src/data/Best10ImagineStudio'
import useBest10ImagineStudioMetadata from '@hooks/best-10-imagine-studio-metadata'
import useMedia, { media } from '@hooks/useMedia'
import useWindowSize from '@hooks/useWindowSize'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import * as cx from './10BestImagineStudio.module.scss'

const Best10ImagineStudioWebsites = ({ data: { page: query } }) => {
  const {
    bannerBG,
    bannerImg,
    bannerImgPhone,
    listMarkLaptop,
    listMarkTablet,
    listMarkPhone,
    listMichelleLaptop,
    listMichelleTablet,
    listMichellePhone,
    listKilianLaptop,
    listKilianTablet,
    listKilianPhone,
    listAngelLaptop,
    listAngelTablet,
    listAngelPhone,
    listFerrettiLaptop,
    listFerrettiTablet,
    listFerrettiPhone,
    listShannaLaptop,
    listShannaTablet,
    listShannaPhone,
    listCityLaptop,
    listCityTablet,
    listCityPhone,
    listDngLaptop,
    listDngTablet,
    listDngPhone,
    listHotLaptop,
    listHotTablet,
    listHotPhone,
    listMatLaptop,
    listMatTablet,
    listMatPhone,
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
    logo8,
    logo9,
    logo10,
    featuresImg,
    featuresAccent,
    lcfBG,
    lcfBGPhone,
  } = useBest10ImagineStudioMetadata()

  const imgListData = [
    {
      laptop: listMarkLaptop,
      tablet: listMarkTablet,
      phone: listMarkPhone,
      identifier: logo1,
    },
    {
      laptop: listMichelleLaptop,
      tablet: listMichelleTablet,
      phone: listMichellePhone,
      identifier: logo2,
    },
    {
      laptop: listKilianLaptop,
      tablet: listKilianTablet,
      phone: listKilianPhone,
      identifier: logo3,
    },
    {
      laptop: listAngelLaptop,
      tablet: listAngelTablet,
      phone: listAngelPhone,
      identifier: logo4,
    },
    {
      laptop: listFerrettiLaptop,
      tablet: listFerrettiTablet,
      phone: listFerrettiPhone,
      identifier: logo5,
    },
    {
      laptop: listShannaLaptop,
      tablet: listShannaTablet,
      phone: listShannaPhone,
      identifier: logo6,
    },
    {
      laptop: listCityLaptop,
      tablet: listCityTablet,
      phone: listCityPhone,
      identifier: logo7,
    },
    {
      laptop: listDngLaptop,
      tablet: listDngTablet,
      phone: listDngPhone,
      identifier: logo8,
    },
    {
      laptop: listHotLaptop,
      tablet: listHotTablet,
      phone: listHotPhone,
      identifier: logo9,
    },
    {
      laptop: listMatLaptop,
      tablet: listMatTablet,
      phone: listMatPhone,
      identifier: logo10,
    },
  ]
  const reviewItems = query?.productISInputs?.reviews?.items.map(
    (item, index) => {
      const video = item.video_link != '' ? item.video_link : null
      const template = item.mobile.url != null ? 'tablet-mobile' : 'tablet'
      const mobile = item.mobile.url != null ? item.mobile.url : null
      return {
        name: item.client_name,
        domain: item.client_domain,
        video: video,
        testimonial: item.testimonials,
        background: {
          src: item.background.url,
          width: item.background.width,
          height: item.background.height,
        },
        logo: {
          src: item.logo.url,
          width: item.logo.width,
          height: item.logo.height,
        },
        logoLarge: {
          src: item.logo.url,
          width: item.logo.width,
          height: item.logo.height,
        },
        agent: {
          src: item.client_image.url,
          width: item.client_image.width,
          height: item.client_image.height,
        },
        template: template,
        tablet: item.tablet.url,
        mobile: mobile,
      }
    }
  )
  const isDesktop = useWindowSize().width >= 1024
  const isPhone = useWindowSize().width <= 767

  return (
    <LayoutInnerPage
      hasWhiteTransparentNav
      noFloatingShareButtons
      wrapperClassName={cx.wrapper}
    >
      <Seo title={page.title} uri={page.uri} />
      <RealEstateBanner
        breadcrumbs={page.breadcrumbs}
        wrapperClassName={cx.banner}
        heading={bannerData.heading.text}
        subheading={bannerData.subheading.text}
        bg={extractImage(bannerBG)}
        img={extractImage(isPhone ? bannerImgPhone : bannerImg)}
      />
      <Best10RealEstate
        hasIdentifier
        hasScrollLaptop
        wrapperClassName={cx.list}
        itemLayout={listData.list.itemLayout}
        heading={listData.heading.text}
        subheading={listData.subheading.text}
        buttonText={listData.list.buttonText}
        items={listData.list.items}
        imgItems={imgListData}
        identifierLayout={listData.list.identifierLayout}
        identifierLink={listData.list.identifierLink}
        scrollLaptopBackcover={listData.list.scrollLaptopBackcover}
      />
      <Best10Features
        hasButton
        wrapperClassName={cx.features}
        heading={featuresData.heading.text}
        subheading={featuresData.subheading.text}
        description={featuresData.description.text}
        img={extractImage(featuresImg)}
        accent={extractImage(featuresAccent)}
        imgAlt={featuresData.imgAlt}
        bestItems={featuresData.best}
        highlightsItems={featuresData.highlights}
        buttonText={featuresData.buttonText}
        buttonLink={featuresData.buttonLink}
      />
      <ImagineStudioReviews
        wrapperClassName={cx.reviews}
        reviewItems={reviewItems}
      />
      <LeadContactForm
        version={page.lcf.version}
        modelOrBackground={page.lcf.model}
        background={getImage(isPhone ? lcfBGPhone : lcfBG)}
        title={page.lcf.title}
        subtitle={page.lcf.subtitle}
        submitLabel={page.lcf.submitLabel}
        leadName={page.lcf.leadName}
        layout={page.lcf.layout}
      />
    </LayoutInnerPage>
  )
}

export default Best10ImagineStudioWebsites

export const pageQuery = graphql`
  query {
    page: wpPage(databaseId: { eq: 674 }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")
      productISInputs {
        reviews {
          title
          description
          button_label
          button_url
          items {
            client_name
            client_domain
            video_link
            testimonials
            background {
              url
              width
              height
              sizes
              srcset
              alt
              modified
            }
            client_image {
              url
              width
              height
              sizes
              srcset
              alt
              modified
            }
            logo {
              url
              width
              height
              sizes
              srcset
              alt
              modified
            }
            tablet {
              url
              width
              height
              sizes
              srcset
              alt
              modified
            }
            mobile {
              url
              width
              height
              sizes
              srcset
              alt
              modified
            }
          }
        }
      }
    }
  }
`
