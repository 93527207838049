import { useStaticQuery, graphql } from 'gatsby'

const useBest10ImagineStudioMetadata = () => {
  const query = useStaticQuery(graphql`
    query best10ImagineStudioMetadata {
      bannerBG: file(
        relativePath: { eq: "best-10-imagine-studio/banner/bg.jpg" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }
      bannerImg: file(
        relativePath: { eq: "best-10-imagine-studio/banner/img.png" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }
      bannerImgPhone: file(
        relativePath: { eq: "best-10-imagine-studio/banner/img-phone.png" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }

      listMarkLaptop: file(
        relativePath: { eq: "best-10-agent-pro/list/mark-laptop-full.jpg" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }
      listMarkTablet: file(
        relativePath: { eq: "best-10-agent-pro/list/mark-tablet.png" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }
      listMarkPhone: file(
        relativePath: { eq: "best-10-agent-pro/list/mark-phone.png" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }
      listMichelleLaptop: file(
        relativePath: { eq: "best-10-agent-pro/list/michelle-laptop-full.jpg" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }
      listMichelleTablet: file(
        relativePath: { eq: "best-10-agent-pro/list/michelle-tablet.png" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }
      listMichellePhone: file(
        relativePath: { eq: "best-10-agent-pro/list/michelle-phone.png" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }
      listKilianLaptop: file(
        relativePath: { eq: "best-10-agent-pro/list/kilian-laptop-full.jpg" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }
      listKilianTablet: file(
        relativePath: { eq: "best-10-agent-pro/list/kilian-tablet.png" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }
      listKilianPhone: file(
        relativePath: { eq: "best-10-agent-pro/list/kilian-phone.png" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }
      listAngelLaptop: file(
        relativePath: { eq: "best-10-agent-pro/list/angel-laptop-full.jpg" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }
      listAngelTablet: file(
        relativePath: { eq: "best-10-agent-pro/list/angel-tablet.png" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }
      listAngelPhone: file(
        relativePath: { eq: "best-10-agent-pro/list/angel-phone.png" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }
      listFerrettiLaptop: file(
        relativePath: { eq: "best-10-agent-pro/list/ferretti-laptop-full.jpg" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }
      listFerrettiTablet: file(
        relativePath: { eq: "best-10-agent-pro/list/ferretti-tablet.png" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }
      listFerrettiPhone: file(
        relativePath: { eq: "best-10-agent-pro/list/ferretti-phone.png" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }
      listShannaLaptop: file(
        relativePath: { eq: "best-10-agent-pro/list/shanna-laptop-full.jpg" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }
      listShannaTablet: file(
        relativePath: { eq: "best-10-agent-pro/list/shanna-tablet.png" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }
      listShannaPhone: file(
        relativePath: { eq: "best-10-agent-pro/list/shanna-phone.png" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }
      listCityLaptop: file(
        relativePath: { eq: "best-10-agent-pro/list/city-laptop-full.jpg" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }
      listCityTablet: file(
        relativePath: { eq: "best-10-agent-pro/list/city-tablet.png" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }
      listCityPhone: file(
        relativePath: { eq: "best-10-agent-pro/list/city-phone.png" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }
      listDngLaptop: file(
        relativePath: { eq: "best-10-agent-pro/list/dng-laptop-full.jpg" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }
      listDngTablet: file(
        relativePath: { eq: "best-10-agent-pro/list/dng-tablet.png" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }
      listDngPhone: file(
        relativePath: { eq: "best-10-agent-pro/list/dng-phone.png" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }
      listHotLaptop: file(
        relativePath: { eq: "best-10-agent-pro/list/hot-laptop-full.jpg" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }
      listHotTablet: file(
        relativePath: { eq: "best-10-agent-pro/list/hot-tablet.png" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }
      listHotPhone: file(
        relativePath: { eq: "best-10-agent-pro/list/hot-phone.png" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }
      listMatLaptop: file(
        relativePath: { eq: "best-10-agent-pro/list/mat-laptop-full.jpg" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }
      listMatTablet: file(
        relativePath: { eq: "best-10-agent-pro/list/mat-tablet.png" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }
      listMatPhone: file(
        relativePath: { eq: "best-10-agent-pro/list/mat-phone.png" }
      ) {
        id
        ...Best10ImagineStudioImageOptimize
      }

      logo1: file(
        relativePath: { eq: "best-10-imagine-studio/logos/sample-1.jpg" }
      ) {
        id
        ...Best10SemiCustomImageOptimize
      }
      logo2: file(
        relativePath: { eq: "best-10-imagine-studio/logos/sample-2.jpg" }
      ) {
        id
        ...Best10SemiCustomImageOptimize
      }
      logo3: file(
        relativePath: { eq: "best-10-imagine-studio/logos/sample-3.jpg" }
      ) {
        id
        ...Best10SemiCustomImageOptimize
      }
      logo4: file(
        relativePath: { eq: "best-10-imagine-studio/logos/sample-4.jpg" }
      ) {
        id
        ...Best10SemiCustomImageOptimize
      }
      logo5: file(
        relativePath: { eq: "best-10-imagine-studio/logos/sample-5.jpg" }
      ) {
        id
        ...Best10SemiCustomImageOptimize
      }
      logo6: file(
        relativePath: { eq: "best-10-imagine-studio/logos/sample-6.jpg" }
      ) {
        id
        ...Best10SemiCustomImageOptimize
      }
      logo7: file(
        relativePath: { eq: "best-10-imagine-studio/logos/sample-7.jpg" }
      ) {
        id
        ...Best10SemiCustomImageOptimize
      }
      logo8: file(
        relativePath: { eq: "best-10-imagine-studio/logos/sample-8.jpg" }
      ) {
        id
        ...Best10SemiCustomImageOptimize
      }
      logo9: file(
        relativePath: { eq: "best-10-imagine-studio/logos/sample-9.jpg" }
      ) {
        id
        ...Best10SemiCustomImageOptimize
      }
      logo10: file(
        relativePath: { eq: "best-10-imagine-studio/logos/sample-10.jpg" }
      ) {
        id
        ...Best10SemiCustomImageOptimize
      }

      featuresImg: file(
        relativePath: { eq: "best-10-imagine-studio/features/img.png" }
      ) {
        id
        ...Best10SemiCustomImageOptimize
      }
      featuresAccent: file(
        relativePath: { eq: "best-10-imagine-studio/features/accent.jpg" }
      ) {
        id
        ...Best10SemiCustomImageOptimize
      }

      lcfBG: file(
        relativePath: { eq: "best-10-imagine-studio/lcf/lcf-bg.jpg" }
      ) {
        id
        ...Best10SemiCustomImageOptimize
      }
      lcfBGPhone: file(
        relativePath: { eq: "best-10-imagine-studio/lcf/lcf-bg-phone.jpg" }
      ) {
        id
        ...Best10SemiCustomImageOptimize
      }
    }

    fragment Best10ImagineStudioImageOptimize on File {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, quality: 90, formats: [AUTO])
      }
    }
  `)

  return query
}

export default useBest10ImagineStudioMetadata
